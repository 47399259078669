import { RouteInfo } from "./vertical-menu.metadata";
import { Roles } from 'app/roles';

const { ADMIN, MANAGER, CREATOR, RIGHT_HOLDER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, RIGHT_HOLDER_TEAM, CREATOR_TEAM } = Roles;

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
  },
  {
    path: "/dashboard/cid",
    title: "Analytics",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
  },
  {
    path: "/creators/profile",
    title: "Profile",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR, CREATOR_TEAM],
  },
  {
    path: "/team-members",
    title: "Team Members",
    icon: "ft-users",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [RIGHT_HOLDER, CREATOR],
  },
  {
    path: "/right-holders/profile",
    title: "Profile",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [RIGHT_HOLDER, RIGHT_HOLDER_TEAM],
  },
  {
    path: "/admins",
    title: "Admin",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN],
  },
  {
    path: "",
    title: "Accounts",
    icon: "ft-users",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    submenu: [
      {
        path: "/creators",
        title: "Creators / Channel Owners",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "/right-holders",
        title: "Content Owners / Speech Owners",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "/contracts",
        title: "All Agreements",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
    ],
  },
  {
    path: "/upload",
    title: "Upload",
    icon: "ft-upload",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    submenu: [],
  },
  {
    path: "/contracts",
    title: "Your Agreements",
    icon: "ft-users",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    hasaccess: [CREATOR],
    submenu: [],
  },
  {
    path: "",
    title: "Youtube Content ID",
    icon: "ft-shield",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "assets/draft-asset",
        title: "Assets Draft",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "assets/pending-review",
        title: "Assets Pending Review",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },

      {
        path: "assets/assets-library",
        title: "Assets Library",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      },
      {
        path: "assets/claim-video",
        title: "Claimed Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      }],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
  },
  {
    path: "",
    title: "YouTube Licensing",
    icon: "ft-video",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/content-library/speakers",
        title: "Licensing Library",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "",
        title: "Library Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
          {
            path: "/energy-level",
            title: "Energy Level Settings",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
          },
          {
            path: "/categories",
            title: "Category Settings",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
          },
        ],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/channels",
        title: "Channels",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      },
      {
        path: "/drm-youtube-videos",
        title: "DRM YouTube Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      }],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
  },

  {
    path: "",
    title: "YouTube",
    icon: "ft-video",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/channels",
        title: "Channels",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [CREATOR, CREATOR_TEAM],
      },
      {
        path: "/channels",
        title: "Sublicensee Channels",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [RIGHT_HOLDER, RIGHT_HOLDER, RIGHT_HOLDER_TEAM],
      },
      {
        path: "/drm-youtube-videos",
        title: "DRM YouTube Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [CREATOR, CREATOR_TEAM],
      },
      {
        path: "/drm-youtube-videos",
        title: "Sublicensee Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [RIGHT_HOLDER, RIGHT_HOLDER_TEAM],
      },
      {
        path: "/sublicensee-creators",
        title: "Sublicensee Creators",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [RIGHT_HOLDER],
      },

    ],
    hasaccess: [CREATOR, RIGHT_HOLDER, RIGHT_HOLDER_TEAM, CREATOR_TEAM],
  },
  {
    path: "",
    title: "YouTube Monitoring",
    icon: "ft-video",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/monitoring-channels",
        title: "Monitored Channels",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "/monitoring-youtube-videos",
        title: "Monitored Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
  },


  {
    path: "/content-library/my-library",
    title: "My Library",
    icon: "ft-play",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR, RIGHT_HOLDER, RIGHT_HOLDER_TEAM, CREATOR_TEAM],
  },
  {
    path: "/artists",
    title: "Speakers",
    icon: "ft-mic",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "/browse",
    title: "Browse",
    icon: "ft-shopping-cart",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR, CREATOR_TEAM],
  },
  {
    path: "",
    title: "Revenue",
    icon: "ft-message-square",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "royalties-earnings",
        title: "Overview",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, VIEWER, CREATOR, RIGHT_HOLDER],
      },
      {
        path: "royalties/by-creator",
        title: "By Creators",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, VIEWER],
      },
      {
        path: "royalties/by-right-holders",
        title: "By Content Owners",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, VIEWER, CREATOR],
      },
      {
        path: "royalties/by-videos",
        title: "By YouTube Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, VIEWER, CREATOR],
      },
      {
        path: "royalties/by-videos",
        title: "By Sublicensee Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [RIGHT_HOLDER],
      },
    ],
    hasaccess: [ADMIN, MANAGER, VIEWER, CREATOR, RIGHT_HOLDER],
  },
  {
    path: "",
    title: "Payments",
    icon: "ft-dollar-sign",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/payment-setting",
        title: "Payment Setting",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
      },
      {
        path: "",
        title: "Payment Details",
        icon: "ft-arrow-right submenu-icon",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
          {
            path: "payment-history/landing-page",
            title: "Overview",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
          },
          {
            path: "payment-history/by-creator",
            title: "By Creators",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
          },
          {
            path: "payment-history/by-channel",
            title: "By Channel",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
          },
          {
            path: "payment-history/by-right-holders",
            title: "By Content Owners",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
          },

          {
            path: "payment-history/by-videos",
            title: "By YouTube videos",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
          },
        ],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
      },
    ],
    hasaccess: [ADMIN, MANAGER],
  },
  {
    path: "",
    title: "Payment History",
    icon: "ft-arrow-right submenu-icon",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "payment-history/landing-page",
        title: "Overview",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [CREATOR, RIGHT_HOLDER],
      },

      {
        path: "payment-history/by-channel",
        title: "By Channel",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [RIGHT_HOLDER],
      },

    ],
    hasaccess: [CREATOR, RIGHT_HOLDER],
  },

  {
    path: "",
    title: "Communication",
    icon: "ft-mail",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/inquiry-list",
        title: "Pending Requests",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/contact-us",
        title: "Contact Us",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: '', badgeClass: '',
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },

    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "/activity-log-reports",
    title: "Activity Log Reports",
    icon: "ft-clock",
    class: "",
    badge: '', badgeClass: '',
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "/contact-us",
    title: "Messages",
    icon: "ft-mail",
    class: "",
    badge: '', badgeClass: '',
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR],
  },
  {
    path: "https://mindsetdrm.com/faq-speakers/",
    title: "FAQ",
    icon: "ft-external-link",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: true,
    submenu: [],
    hasaccess: [CREATOR],
  },
];
